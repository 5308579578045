/*
  Auto-generated by Spline
*/

import useSpline from "@splinetool/r3f-spline";
import React from "react";

const Gallery = React.forwardRef((props, ref) => {
  const { nodes, materials } = useSpline(
    "https://prod.spline.design/qImwZ18cGEb7loVy/scene.splinecode"
  );
  return (
    <>
      <group
        {...props}
        dispose={null}
        position={[23, -50, 0]}
        ref={ref}
        scale={[0.08, 0.08, 0.08]}
      >
        <mesh
          name="Rectangle 5"
          geometry={nodes["Rectangle 5"].geometry}
          material={materials["Rectangle 5 Material"]}
          castShadow
          receiveShadow
          position={[-150, 99.2, 0.86]}
          rotation={[Math.PI, -Math.PI / 2, 0]}
          scale={0.85}
        />
        <mesh
          name="Rectangle 4"
          geometry={nodes["Rectangle 4"].geometry}
          material={materials["Rectangle 4 Material"]}
          castShadow
          receiveShadow
          position={[0, 99.2, -150.35]}
          scale={0.85}
        />
        <mesh
          name="Rectangle 3"
          geometry={nodes["Rectangle 3"].geometry}
          material={materials["Rectangle 3 Material"]}
          castShadow
          receiveShadow
          position={[148.97, 99.2, -0.05]}
          rotation={[0, Math.PI / 2, 0]}
          scale={0.85}
        />
        <mesh
          name="Rectangle 2"
          geometry={nodes["Rectangle 2"].geometry}
          material={materials["Rectangle 2 Material"]}
          castShadow
          receiveShadow
          position={[0, 99.2, 150.86]}
          scale={0.85}
        />
      </group>
    </>
  );
});

export default Gallery;
